import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    medChartItems: [],
    oralInputTotal: [],
    outputTotal: [],
    fluidTotal: [],
    bloodTotal: [],
    allergies: [],
    ivMedicationTotal: [],
    ICCDrain: 0,
    patientWeight: 'N/A',
    patientWeightEstimated: '',
};

export const FluidsDrugsSlice = createSlice({
    name: 'icd10',
    initialState,
    reducers: {
        medChartItemsAction: (state, action) => {
            state.medChartItems = action.payload;
        },
        oralInputTotalAction: (state, action) => {
            if (action.payload.isClear === true) {
                if (state.oralInputTotal !== undefined && state.oralInputTotal !== null && state.oralInputTotal.length > 0) {
                    state.oralInputTotal = state.oralInputTotal.slice(0, 0);
                }
            }
            else {
                const existObj = state.oralInputTotal.find(el => el.groupByMedicationId === action.payload.groupByMedicationId)
                if (!existObj) {
                    state.oralInputTotal = state.oralInputTotal.concat(action.payload)
                }
                else if (existObj) {
                    const removedItem = state.oralInputTotal.filter(el => el.groupByMedicationId !== action.payload.groupByMedicationId)
                    state.oralInputTotal = [...removedItem, action.payload];
                }
                else if (existObj && action.payload.invalidated_at) {
                    const removedItem = state.oralInputTotal.filter(el => el.groupByMedicationId !== action.payload.groupByMedicationId)
                    state.oralInputTotal = [...removedItem]
                }
            }
        },
        ivMedicationTotalAction: (state, action) => {
            if (action.payload.isClear === true) {
                if (state.ivMedicationTotal !== undefined && state.ivMedicationTotal !== null && state.ivMedicationTotal.length > 0) {
                    state.ivMedicationTotal = state.ivMedicationTotal.slice(0, 0);
                }
            }
            else {
                const existObj = state.ivMedicationTotal.find(el => el.groupByMedicationId === action.payload.groupByMedicationId)
                if (!existObj) {
                    state.ivMedicationTotal = state.ivMedicationTotal.concat(action.payload)
                }
                else if (existObj) {
                    const removedItem = state.ivMedicationTotal.filter(el => el.groupByMedicationId !== action.payload.groupByMedicationId)
                    state.ivMedicationTotal = [...removedItem, action.payload];
                }
                else if (existObj && action.payload.invalidated_at) {
                    const removedItem = state.ivMedicationTotal.filter(el => el.groupByMedicationId !== action.payload.groupByMedicationId)
                    state.ivMedicationTotal = [...removedItem]
                }
            }
        },
        outputTotalAction: (state, action) => {
            if (action.payload.isClear === true) {
                if (state.outputTotal !== undefined && state.outputTotal !== null && state.outputTotal.length > 0) {
                    state.outputTotal = state.outputTotal.slice(0, 0);
                }
            }
            else {
                const existObj = state.outputTotal.find(el => el.groupByMedicationId === action.payload.groupByMedicationId)
                if (!existObj) {
                    state.outputTotal = state.outputTotal.concat(action.payload)
                }
                else if (existObj) {
                    const removedItem = state.outputTotal.filter(el => el.groupByMedicationId !== action.payload.groupByMedicationId)
                    state.outputTotal = [...removedItem, action.payload];
                }
                else if (existObj && action.payload.invalidated_at) {
                    const removedItem = state.outputTotal.filter(el => el.groupByMedicationId !== action.payload.groupByMedicationId)
                    state.outputTotal = [...removedItem]
                }
            }
        },
        fluidTotalAction: (state, action) => {
            if (action.payload.isClear === true) {
                if (state.fluidTotal !== undefined && state.fluidTotal !== null && state.fluidTotal.length > 0) {
                    state.fluidTotal = state.fluidTotal.slice(0, 0);
                }
            }
            else {
                const existObj = state.fluidTotal.find(el => el.groupByMedicationId === action.payload.groupByMedicationId)
                if (!existObj) {
                    state.fluidTotal = state.fluidTotal.concat(action.payload);
                }
                else if (existObj) {
                    const removedItem = state.fluidTotal.filter(el => el.groupByMedicationId !== action.payload.groupByMedicationId)
                    state.fluidTotal = [...removedItem, action.payload];
                }
                else if (existObj && action.payload.invalidated_at) {
                    const removedItem = state.fluidTotal.filter(el => el.groupByMedicationId !== action.payload.groupByMedicationId)
                    state.fluidTotal = [...removedItem]
                }
            }
        },
        bloodTotalAction: (state, action) => {
            if (action.payload.isClear === true) {
                if (state.bloodTotal !== undefined && state.bloodTotal !== null && state.bloodTotal.length > 0) {
                    state.bloodTotal = state.bloodTotal.slice(0, 0);
                }
            }
            else {
                const existObj = state.bloodTotal.find(el => el.groupByMedicationId === action.payload.groupByMedicationId)
                if (!existObj) {
                    state.bloodTotal = state.bloodTotal.concat(action.payload);
                }
                else if (existObj) {
                    const removedItem = state.bloodTotal.filter(el => el.groupByMedicationId !== action.payload.groupByMedicationId)
                    state.bloodTotal = [...removedItem, action.payload];
                }
                else if (existObj && action.payload.invalidated_at) {
                    const removedItem = state.bloodTotal.filter(el => el.groupByMedicationId !== action.payload.groupByMedicationId)
                    state.bloodTotal = [...removedItem]
                }
            }
        },
        ICCDrainAction: (state, action) => {
            state.ICCDrain = action.payload
        },
        patientWeightAction: (state, action) => {
            state.patientWeight = action.payload;
        },
        patientWeightEstimatedAction: (state, action) => {
            state.patientWeightEstimated = action.payload;
        },
        allergiesAction: (state, action) => {
            state.allergies = action.payload;
        },
    },
});

export const { bloodTotalAction, medChartItemsAction, ivMedicationTotalAction, oralInputTotalAction, outputTotalAction, fluidTotalAction, ICCDrainAction,
    patientWeightAction, allergiesAction, patientWeightEstimatedAction } = FluidsDrugsSlice.actions;

export default FluidsDrugsSlice.reducer;
