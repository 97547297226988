import React from 'react';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import advanced from 'dayjs/plugin/advancedFormat'

/********************** Time zone related function **************************/

export const displayAbbrText_TZ = (item) => {
    let name = dayjs().tz(item).format('zzz');
    if (name !== "") {
        if (name.split(" ").length === 2) {
            name = name.replace(" Time", "");
        }
        else if (name.indexOf("Cocos") >= 0) {
            name = "CCT"
        }
        else if (name.indexOf("Christmas") >= 0) {
            name = "CXT"
        }
        else {
            name = name.split(' ').map(word => word.charAt(0)).join('');
        }
    }
    else {
        const moment = require('moment-timezone');
        name = moment.tz(new Date(), item).zoneAbbr;
    }
    return name;
}

export const displayAbbrText = (item) => {
    const moment = require('moment-timezone');
    let dateString = moment.tz(new Date(), item).format().toString();
    if (dateString.indexOf("+") > 0) {
        return ("UTC +" + dateString.split("+")[1]);
    }
    else {
        return ("UTC -" + dateString.split("-")[1]);
    }
}

export const CurrentTimeZoneOffset = () => {
    const moment = require('moment-timezone');
    let region = localStorage.getItem('region');
    let dateString = moment.tz(new Date(), region).format().toString();
    if (dateString.indexOf("+") > 0) {
        return (dateString.split("+")[1]);
    }
    else {
        return (dateString.split("-")[1]);
    }
}


/****************************************************************************************/

/********************** Input Related Items **************************/

export const NumberWithFrontDecimal = (value) => {
    let TargetValue = value
        if (TargetValue !== "" && TargetValue.charAt(0) === ".") {
            TargetValue = "0" + TargetValue;
    }

    if (TargetValue !== "" && TargetValue !== undefined && TargetValue !== null) {
        return TargetValue.toString();
    }
    return TargetValue;
}

export const NumberWithoutDecimalPoint = (value) => {
    if (value !== "" && value.indexOf('.') > 0) {
        return parseFloat(value).toString();
    }
    return value;
}

export const hasValue = (value) => {
    if (value === "" || value === null || value === undefined) {
        return false;
    }
    return true;
}