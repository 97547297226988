import { configureStore } from '@reduxjs/toolkit';

import CustomizerReducer from './customizer/CustomizerSlice';
import FluidsDrugsReducer from './Fluid_Drugs/fluid_drugSlice';
import Icd10Reducer from './libraryslice/Icd10Slice';
import SidebarPatientFormsSectionReducer from './sidebarPatientformsSection/sidebarPatientformsSectionSlice';
import ObsChartReducer from './obschart/obsChartSlice';
import patientTaskSlice from './patientTask/patientTaskSlice';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    icd10: Icd10Reducer,
    fluidsDrugs: FluidsDrugsReducer,
    sidebarPatientFormsSection: SidebarPatientFormsSectionReducer,
    ObsChart: ObsChartReducer,
    patientTask: patientTaskSlice
  },
});

export default store;
