import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  icd10Array: [],
  dropdownValue: '',
  yesNoisplayReflecting: '',
  editReasonModal: false
};

export const Icd10Slice = createSlice({
  name: 'icd10',
  initialState,
  reducers: {
    ChangeIcd10: (state, action) => {
      state.icd10Array = action.payload;
    },
    ChangeDropDownOnAutocompleteChange: (state, action) => {
      state.dropdownValue = action.payload;
    },
    ChangeYesNoDisplayReflecting: (state, action) => {
      state.yesNoisplayReflecting = action.payload;
    },
    ChangeEditReasonState: (state, action) => {
      state.editReasonModal = action.payload;
    },
  },
});

export const { ChangeIcd10, ChangeDropDownOnAutocompleteChange, ChangeYesNoDisplayReflecting, ChangeEditReasonState } =
  Icd10Slice.actions;

export default Icd10Slice.reducer;
