import BACKEND_HOST from '../constant';
import { checkAccessTokenValid } from '../utils/AuthHelper';

const Apifetch = async (url, methodtype, body = {}, signal = null) => {
    const apiurl = BACKEND_HOST.concat(url);
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    if (localStorage) {
        if (localStorage.getItem('auth')) {
            const { token } = JSON.parse(localStorage.getItem('auth'));
            const { id } = JSON.parse(localStorage.getItem('auth'));

            myHeaders.append('Authorization', `Bearer ${token}`);
            myHeaders.append('FrontendUserId', `${id}`);
            myHeaders.append('RequestFrom', `Admin`);
        }
    }

    if (methodtype === "GET") {
        const request = new Request(apiurl, {
            method: methodtype,
            headers: myHeaders,
            signal: signal
        });

        return fetch(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                if (!checkAccessTokenValid(response)) window.location.href = "/auth/login";
                throw new Error(response.statusText);
            }
            return response.json();
        });
    }
    else if (methodtype === "POST" || methodtype === "DELETE" || methodtype === "PUT") {
        const request = new Request(apiurl, {
            method: methodtype,
            headers: myHeaders,
            body: body
        });

        return fetch(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                if (!checkAccessTokenValid(response)) window.location.href = "/auth/login";
                if (response.statusText === "") {
                    return response.json();
                }
                throw new Error(response.statusText);
            }
            return response.json();
        });
    }

};


export default Apifetch;
