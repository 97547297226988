import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { Nav } from 'reactstrap';
import Loadable from '../layouts/loader/Loadable';
import { isAuthicated, isRoleCorrect } from '../utils/AuthHelper';
import TabOrder from '../views/manage/Settings/FormParam/TabOrder';
/****Layouts*****/
const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
/***** Pages ****/

const Starter = Loadable(lazy(() => import('../views/Starter')));
const About = Loadable(lazy(() => import('../views/About')));
const Alerts = Loadable(lazy(() => import('../views/ui/Alerts')));
const Badges = Loadable(lazy(() => import('../views/ui/Badges')));
const Buttons = Loadable(lazy(() => import('../views/ui/Buttons')));
const Cards = Loadable(lazy(() => import('../views/ui/Cards')));
const Grid = Loadable(lazy(() => import('../views/ui/Grid')));
const Tables = Loadable(lazy(() => import('../views/ui/Tables')));
const Forms = Loadable(lazy(() => import('../views/ui/Forms')));
const Breadcrumbs = Loadable(lazy(() => import('../views/ui/Breadcrumbs')));

// custom
const DropdownList = Loadable(lazy(() => import('../views/DropDownList')));
const FormList = Loadable(lazy(() => import('../views/FormList')));
const DropDownOptionsList = Loadable(lazy(() => import('../views/DropDowns/DropDownOptionsList')));
const FormListOptionsList = Loadable(lazy(() => import('../views/FormsList/FormListOptionsList')));
const MedicationsLib = Loadable(lazy(() => import('../views/medicationLibrary/MedicationLib')));
const FluidLib = Loadable(lazy(() => import('../views/medicationLibrary/FluidLib')));
const BloodLib = Loadable(lazy(() => import('../views/medicationLibrary/BloodLib')));
const ErrorAuditLogs = Loadable(lazy(() => import('../views/datamanagement/errorlog/ErrorAuditLogs')));
const ErrorAuditLogDetails = Loadable(lazy(() => import('../views/datamanagement/errorlog/ErrorAuditLogDetails')));
const DataIntegrityExceptions = Loadable(lazy(() => import('../views/datamanagement/dataintegrityexceptions/DataIntegrityExceptions')));
const AddDataIntegrityException = Loadable(lazy(() => import('../views/datamanagement/dataintegrityexceptions/AddDataIntegrityException')));
const DataEditLog = Loadable(lazy(() => import('../views/datamanagement/DataEditLog')));
const DataIssues = Loadable(lazy(() => import('../views/datamanagement/dataIssues/DataIssues')));
const DataIssueLog = Loadable(lazy(() => import('../views/datamanagement/DataIssueLog')));
const FormsEditor = Loadable(lazy(() => import('../views/formeditor/FormEditor')));
const FormEditorFormFields = Loadable(lazy(() => import('../views/formeditor/FormEditorFormFields')));
const Dispatch = Loadable(lazy(() => import('../views/formeditor/Dispatch')));
const SystemAdmin = Loadable(lazy(() => import('../views/systemadmin/SystemAdmin')));
const EmailAttachment = Loadable(lazy(() => import('../views/patientdetails/EmailAttachment')));
const Tasks = Loadable(lazy(() => import('../views/patientdetails/Tasks')));
const BasicObs = Loadable(lazy(() => import('../views/obs/BasicObs')));
const MedicationChart = Loadable(lazy(() => import('../views/medication-chart/MedicationChart')));
const Icd10 = Loadable(lazy(() => import('../views/icd10library/icd10List')));
const IvtLib = Loadable(lazy(() => import('../views/medicationLibrary/IvtFluidLib')));
const ComplianceReport = Loadable(lazy(() => import('../views/reports/ComplianceReport')));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));
const LoginFormik = Loadable(lazy(() => import('../views/auth/LoginFormik')));
const Maintanance = Loadable(lazy(() => import('../views/auth/Maintanance')));
const LockScreen = Loadable(lazy(() => import('../views/auth/LockScreen')));
const RecoverPassword = Loadable(lazy(() => import('../views/auth/RecoverPassword')));

/***** Patient Forms ****/
const PatientForms = Loadable(lazy(() => import('../views/forms/patientforms')));

/***** Manage ****/
const ManageUserPage = Loadable(lazy(() => import('../views/manage/ManageUser/ManageUserPage')));
const ManagePINParam = Loadable(lazy(() => import('../views/manage/Pin/ManagePinParam')));
const MaintenanceMode = Loadable(lazy(() => import('../views/manage/Pin/MaintenanceMode')));
const ManageRBARules = Loadable(lazy(() => import('../views/manage/RBARules/ManageRBARules')));
const ManageSyncParam = Loadable(lazy(() => import('../views/manage/Security/ManageSyncParam')));
const ManageAppSettings = Loadable(lazy(() => import('../views/manage/Settings/ManageAppSettings')));
const ManageFormSettings = Loadable(lazy(() => import('../views/manage/Settings/FormParam/Security_FormList')));
const ManageTabOrder = Loadable(lazy(() => import('../views/manage/Settings/FormParam/TabOrder')));
const ManagePatientMatrixSettings = Loadable(lazy(() => import('../views/manage/Settings/ManagePatientMatrixSettings')));

/***** Audit ****/
const AuditPage = Loadable(lazy(() => import('../views/Audits/AuditsPage')));
const AuditsTaskPage = Loadable(lazy(() => import('../views/Audits/AuditsTaskPage')));

/*****Routes******/

const ThemeRoutes = [
    {
        path: '/',
        element: <FullLayout />,
        children: [
            {
                path: '/index.html',
                element: (
                    <Navigate to={isAuthicated() ? '/patientdetails/tasks' : '/auth/login'} />
                ),
            },
            {
                path: '/',
                element: (
                    <Navigate to={isAuthicated() ? '/patientdetails/tasks' : '/auth/login'} />
                ),
            },

            // *** Reference page, please delete if we don't need these reference anymore
            //{ path: '/starter', exact: true, element: <Starter /> },
            //{ path: '/about', exact: true, element: <About /> },
            //{ path: '/ui/alerts', exact: true, element: <Alerts /> },
            //{ path: '/badges', exact: true, element: <Badges /> },
            //{ path: '/buttons', exact: true, element: <Buttons /> },
            //{ path: '/cards', exact: true, element: <Cards /> },
            //{ path: '/grid', exact: true, element: <Grid /> },
            //{ path: '/table', exact: true, element: <Tables /> },
            //{ path: '/forms', exact: true, element: <Forms /> },
            //{ path: '/breadcrumbs', exact: true, element: <Breadcrumbs /> },

            {
                path: '/patientdetails/tasks',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["Clinician", "Clinician Read Only", "System Admin"]) ? <Tasks pageName='Task List' /> : <Navigate to='/patientdetails/task-merge-list' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/patientdetails/forms/:episodeid',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["Data Admin", "Clinician", "Clinician Read Only", "System Admin"]) ? <PatientForms /> : <Navigate to='/datamanagement/integrityexceptions' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/patientdetails/task-merge-list',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["Data Admin", "System Admin"]) ? <Tasks pageName="Advance Task List" /> : <Navigate to='/auth/login' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/audits',
                exact: true,
                element: isAuthicated() ? isRoleCorrect(["System Admin", "Data Admin"]) ? <AuditPage /> : <Navigate to='/patientdetails/tasks' /> : <Navigate to='/auth/login' />,
            },
            {
                path: '/datamanagement/errorauditlogs',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin", "Data Admin"]) ? <ErrorAuditLogs /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/datamanagement/errorauditlogs/:error_audit_log_id',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin", "Data Admin"]) ? <ErrorAuditLogDetails /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/datamanagement/integrityexceptions',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin", "Data Admin"]) ? <DataIntegrityExceptions /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/datamanagement/addintegrityexception/:data_integrity_id',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin", "Data Admin"]) ? <AddDataIntegrityException /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/datamanagement/dataeditlog/:episodeid',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin", "Data Admin"]) ? <DataEditLog /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/datamanagement/dataissuelog/:episodeid',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin", "Data Admin"]) ? <DataIssueLog /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/datamanagement/dataissues',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin", "Data Admin"]) ? <DataIssues /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/reports/compliancereport',
                exact: true,
                element: isAuthicated() ? isRoleCorrect(["System Admin", "Data Admin"]) ? <ComplianceReport /> : <Navigate to='/patientdetails/tasks' /> : <Navigate to='/auth/login' />,
            },
            {
                path: '/bloodlib',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin", "Data Admin"]) ? <BloodLib /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/ivtfluidlib',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin", "Data Admin"]) ? <IvtLib /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/drugfluidlib',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin", "Data Admin"]) ? <FluidLib /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/medicationlib',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin", "Data Admin"]) ? <MedicationsLib /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },

            { path: '/dropdowns', name: 'drop downs', exact: true, element: isAuthicated() ? (isRoleCorrect(["System Admin"]) ? <DropdownList /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' /> },
            { path: '/formslist', name: 'formslist', exact: true, element: isAuthicated() ? (isRoleCorrect(["System Admin"]) ? <FormList /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' /> },
            {
                path: '/formslist/:name/:fieldId',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin"]) ? <FormListOptionsList /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/dropdowns/:type/:field_id/:status/:type',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin"]) ? <DropDownOptionsList /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/basicobs',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin"]) ? <BasicObs /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/medcharts',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin"]) ? <MedicationChart /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/formsditor',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin"]) ? <FormsEditor /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/formsditor/dispatch',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin"]) ? <Dispatch /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/formsditor/:name/:fieldId',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin"]) ? <FormEditorFormFields /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/systemadmin',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin"]) ? <SystemAdmin /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/patientdetails/email',
                exact: true,
                element: isAuthicated() ? <EmailAttachment /> : <Navigate to='/auth/login' />,
            },
            {
                path: '/manageuser',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin"]) ? <ManageUserPage /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/managepinparam',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin"]) ? <ManagePINParam /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/managesyncparam',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin"]) ? <ManageSyncParam /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/manageappsettings',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin"]) ? <ManageAppSettings /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/patientmatrixsettings',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin"]) ? <ManagePatientMatrixSettings /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/manageformparam',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin"]) ? <ManageFormSettings /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/managetaborder',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin"]) ? <TabOrder /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/maintenancemode',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin"]) ? <MaintenanceMode /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/manageRBArules',
                exact: true,
                element: isAuthicated() ? (isRoleCorrect(["System Admin"]) ? <ManageRBARules /> : <Navigate to='/patientdetails/tasks' />) : <Navigate to='/auth/login' />,
            },
            {
                path: '/audits/:episodeid',
                exact: true,
                element: isAuthicated() ? isRoleCorrect(["System Admin"]) ? <AuditsTaskPage /> : <Navigate to='/patientdetails/tasks' /> : <Navigate to='/auth/login' />,
            },


            { path: '*', element: <Navigate to="/auth/404" /> },
        ],
    },
    {
        path: '/auth',
        element: <BlankLayout />,
        children: [
            { path: '404', element: <Error /> },
            { path: '*', element: <Navigate to="/auth/404" /> },
            { path: 'login', element: <LoginFormik /> }

            //{ path: 'registerformik', element: <RegisterFormik /> },
            //{ path: 'maintanance', element: <Maintanance /> },
            //{ path: 'lockscreen', element: <LockScreen /> },
            //{ path: 'recoverpwd', element: <RecoverPassword /> },
        ],
    },
];

export default ThemeRoutes;
