import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardTitle, Col, Input, Row, FormGroup, Label, TabContent, TabPane, Nav, NavLink, NavItem } from 'reactstrap';
import { NumericFormat } from 'react-number-format'
import classnames from 'classnames';
import ComponentCard from '../../../../components/ComponentCard';
import CustomBreadCrumbs from '../../../../layouts/breadcrumbs/CustomBreadCrumbs';
import Apifetch from '../../../../network/API_fetch'
import { Link } from 'react-router-dom';
import { SectionOfOperation, SectionOfOperationCubit } from '../../../../utils/SectionOfOperation'
import { hasValue } from '../../../forms/CommonFunctions';

const TabOrder = () => {
    const [success, setsuccess] = useState('')
    const [error, seterror] = useState()

    const [configs, setconfigs] = useState();
    const [configs_obs, setconfigs_obs] = useState();
    const [configs_optional_forms, setconfigs_optional_forms] = useState();
    const [configs_compliance, setconfigs_compliance] = useState();

    const CurrentSection = SectionOfOperationCubit.fromUrlString;

    const [activeTab, setActiveTab] = useState('1');

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const SetForms = async () => {
        const Forms = [];
        const Obs = ['obs', 'complex_obs'];
        const OptionalForms = [];

        if (CurrentSection === SectionOfOperation.QLD) {
            Forms.push("qld.journey")
            Forms.push("qld.examination")
            Forms.push("standard_note")
            Forms.push("qld.fluid.medication")
            Forms.push("qld.fluid.drug_infusion")
            Forms.push("qld.compliance_cqi")
            Forms.push("qld.handover")
            Forms.push("attachments")

            OptionalForms.push("qld.airwayregistry")
            OptionalForms.push("qld.blood_consent")

            Apifetch(`/api/Confgurations/GetSectionOrder?forms=qld.compliance_cqi`, "GET", null).then((response) => {
                setconfigs_compliance(response)
            })
        }
        else if (CurrentSection === SectionOfOperation.SE) {
            Forms.push("se.pfa")
            Forms.push("se.dispatch")
            Forms.push("se.on_arrival")
            Forms.push("se.clinicalmission")
            Forms.push("standard_note")
            Forms.push("attachments")
            Forms.push("se.fluid.medication")
            Forms.push("se.fluid.drug_infusion")
            Forms.push("se.interventions")
            Forms.push("se.discharge")

            OptionalForms.push("se.airwayregistry")
            OptionalForms.push("se.blood_consent")
            OptionalForms.push("se.Stroke_assessment")
        }
        else if (CurrentSection === SectionOfOperation.CENTRAL) {
            Forms.push("central.task")
            Forms.push("standard_note")
            Forms.push("central.fluid.medication")
            Forms.push("central.fluid.drug_infusion")
            Forms.push("central.pfa")
            Forms.push("central.arrivalAsses")
            Forms.push("central.mentalHealth")
            Forms.push("central.kpi")
            Forms.push("central.discharge")
            Forms.push("attachments")
            Forms.push("clinical_coord")

            OptionalForms.push("central.airwayregistry")
            OptionalForms.push("central.blood_consent")
            OptionalForms.push("central.Stroke_assessment")
        }

        Apifetch(`/api/Confgurations/GetFormOrder`, "POST", JSON.stringify(Forms)).then((response) => {
            setconfigs(response)
        })

        Apifetch(`/api/Confgurations/GetFormOrder`, "POST", JSON.stringify(OptionalForms)).then((response) => {
            setconfigs_optional_forms(response)
        })

        Apifetch(`/api/Confgurations/GetFormOrder`, "POST", JSON.stringify(Obs)).then((response) => {
            setconfigs_obs(response)
        })
    }

    useEffect(() => {
        SetForms();
    }, [])

    const handleSave = async () => {
        setsuccess("")
        seterror("")

        for (var i = 0; i < configs.length; i++) {
            var configid = configs[i].id;
            if (formState != null && JSON.stringify(formState).includes(configid)) {
                if (document.getElementById(configid)) {
                    if (document.getElementById(configid).value === "") {
                        toggle('1');
                        setTimeout(function () {
                            document.getElementById(configid).focus();
                        }, 100);

                        seterror("Tab order can not be set as a blank.")
                        return;
                    }
                }
            }
        }

        for (var i = 0; i < configs_obs.length; i++) {
            var configid = configs_obs[i].id;
            if (formState != null && JSON.stringify(formState).includes(configid)) {
                if (document.getElementById(configid)) {
                    if (document.getElementById(configid).value === "") {
                        toggle('2');
                        setTimeout(function () {
                            document.getElementById(configid).focus();
                        }, 100);

                        seterror("Tab order can not be set as a blank.")
                        return;
                    }
                }
            }
        }

        if (CurrentSection === SectionOfOperation.QLD) {
            for (var i = 0; i < configs_compliance.length; i++) {
                var configid = configs_compliance[i].id;
                if (formState != null && JSON.stringify(formState).includes(configid)) {
                    if (document.getElementById(configid)) {
                        if (document.getElementById(configid).value === "") {
                            toggle('3');
                            setTimeout(function () {
                                document.getElementById(configid).focus();
                            }, 100);

                            seterror("Tab order can not be set as a blank.")
                            return;
                        }
                    }
                }
            }
        }

        for (var i = 0; i < configs_optional_forms.length; i++) {
            var configid = configs_optional_forms[i].id;
            if (formState != null && JSON.stringify(formState).includes(configid)) {
                if (document.getElementById(configid)) {
                    if (document.getElementById(configid).value === "") {
                        toggle('4');
                        setTimeout(function () {
                            document.getElementById(configid).focus();
                        }, 100);

                        seterror("Tab order can not be set as a blank.")
                        return;
                    }
                }
            }
        }

        for (var i = 0; i < configs.length; i++) {
            var configid = configs[i].id;
            if (formState != null && JSON.stringify(formState).includes(configid)) {

                var objConfig = {};
                objConfig["id"] = configid;

                var Original_parameter_value = JSON.parse(configs[i].parameter_values)

                var obj = {};
                obj["title"] = Original_parameter_value?.title;
                obj["section"] = Original_parameter_value?.section;
                obj["value"] = "";

                if (document.getElementById(configid)) {
                    if (document.getElementById(configid).type === "checkbox") {
                        obj["value"] = document.getElementById(configid).checked.toString().toLowerCase();
                    }
                }

                objConfig["parameter_values"] = JSON.stringify(obj);

                Apifetch(`/api/Confgurations/UpdateConfiguration`, "PUT", JSON.stringify(objConfig)).then((response) => {

                })
            }
        }
        setsuccess("Configurations saved successfully.")
    };

    const [formState, setFormState] = useState({});
    function setValue(field, value, form_type) {
        setFormState({
            ...formState,
            [field]: {
                ...formState[field],
                value: value,
                form_type: form_type
            }
        })
    }

    function onChange(e, field, form_type) {
        setValue(field, e.target.value, form_type);
    }

    return (
        <>
            <CustomBreadCrumbs root={{ name: 'System Admin', link: '/managetaborder' }} pages={[{ name: 'Tab Order', link: '/managetaborder' }]} />
            <ComponentCard title="Tab Order">
                <Row className='mt-2'>
                    <Col md={12}></Col>
                    <Col>{success !== undefined && success !== '' && <p style={{ color: "green" }}>{success}</p>}
                        {error !== undefined && error !== '' && <p style={{ color: "red" }}>{error}</p>}</Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                                    Default forms
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                                    Extra Obs/ Complex Obs
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }}>
                                    Optional forms
                                </NavLink>
                            </NavItem>
                            {CurrentSection === SectionOfOperation.QLD &&
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }}>
                                        Compliance
                                    </NavLink>
                                </NavItem>
                            }

                        </Nav>
                        <TabContent className="p-4" activeTab={activeTab}>
                            <TabPane tabId="1">
                                {configs && configs.slice().sort((a, b) => (a?.work_flow_group_order || 0) - (b?.work_flow_group_order || 0)).map((item, index) =>
                                    <>
                                        <Row className='mt-2'>
                                            <Col md={2} style={{ padding: "10px" }}>
                                                {item.work_flow_group_name}
                                            </Col>
                                            <Col md={2}>
                                                <NumericFormat customInput={Input} id={item.id} name={item.id} value={item?.work_flow_group_order || 0}
                                                    onWheel={event => event.currentTarget.blur()}
                                                    onBlur={(e) => {
                                                        onChange(e, item.id, "default")
                                                    }}
                                                />
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </>)
                                }
                            </TabPane>
                            <TabPane tabId="2">
                                {configs_obs && configs_obs.slice().sort((a, b) => (a?.work_flow_group_order || 0) - (b?.work_flow_group_order || 0)).map((item, index) =>
                                    <>
                                        <Row className='mt-2'>
                                            <Col md={2} style={{ padding: "10px" }}>
                                                {item.work_flow_group_name}
                                            </Col>
                                            <Col md={2}>
                                                <NumericFormat customInput={Input} id={item.id} name={item.id} value={item?.work_flow_group_order || 0}
                                                    onWheel={event => event.currentTarget.blur()}
                                                    onBlur={(e) => {
                                                        onChange(e, item.id, "obs")
                                                    }}
                                                />
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </>)
                                }
                            </TabPane>
                            {CurrentSection === SectionOfOperation.QLD &&
                                <TabPane tabId="3">
                                    {configs_compliance && configs_compliance.filter(a => a.section_position === "SectionPosition.body" && hasValue(a?.main_section_id) === false).slice().sort((a, b) => (a?.section_order || 0) - (b?.section_order || 0)).map((item, index) =>
                                        <>
                                            <Row className='mt-2'>
                                                <Col md={2} style={{ padding: "10px" }}>
                                                    {item.section_name}
                                                </Col>
                                                <Col md={2}>
                                                    <NumericFormat customInput={Input} id={item.id} name={item.id} value={item?.section_order || 0}
                                                        onWheel={event => event.currentTarget.blur()}
                                                        onBlur={(e) => {
                                                            onChange(e, item.id, "compliance")
                                                        }}
                                                    />
                                                </Col>
                                                <Col></Col>
                                            </Row>
                                            {configs_compliance.filter(b => b.main_section_id === item.id).slice().sort((c, d) => (c?.section_order || 0) - (d?.section_order || 0)).map((item1, index1) =>
                                                <>
                                                    <Row className='mt-2'>
                                                        <Col md={1}></Col>
                                                        <Col md={2} style={{ padding: "10px" }}>
                                                            {item1.section_name}
                                                        </Col>
                                                        <Col md={2}>
                                                            <NumericFormat customInput={Input} id={item.id} name={item.id} value={item?.section_order || 0}
                                                                onWheel={event => event.currentTarget.blur()}
                                                                onBlur={(e) => {
                                                                    onChange(e, item.id, "compliance")
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col></Col>
                                                    </Row>
                                                </>)
                                            }
                                        </>)
                                    }
                                </TabPane>
                            }
                            <TabPane tabId="4">
                                {configs_optional_forms && configs_optional_forms.slice().sort((a, b) => (a?.work_flow_group_order || 0) - (b?.work_flow_group_order || 0)).map((item, index) =>
                                    <>
                                        <Row className='mt-2'>
                                            <Col md={2} style={{ padding: "10px" }}>
                                                {item.work_flow_group_name}
                                            </Col>
                                            <Col md={2}>
                                                <NumericFormat customInput={Input} id={item.id} name={item.id} value={item?.work_flow_group_order || 0}
                                                    onWheel={event => event.currentTarget.blur()}
                                                    onBlur={(e) => {
                                                        onChange(e, item.id, "optional")
                                                    }}
                                                />
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </>)
                                }
                            </TabPane>
                        </TabContent>
                        <Row className='mt-2'>
                            <Col md={3}>
                                <Button color="outline-primary">
                                    Cancel
                                </Button>
                            </Col>
                            <Col md={7}>
                                &nbsp;
                            </Col>
                            <Col md={2}>
                                <Button color="primary" onClick={handleSave}>
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ComponentCard>
        </>
    );
};

export default TabOrder;
