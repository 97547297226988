import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const CustomBreadCrumbs = ({ title, root, pages }) => {
  // const directTo = `/`;
  return (
    <>
      <h4 className="text-capitalize">{title}</h4>
      <Breadcrumb>
        <BreadcrumbItem to={root.link} tag={Link} className="text-decoration-none">
          {root.name}
        </BreadcrumbItem>
        {pages.map((page) => (
          <BreadcrumbItem to={page.link} className="text-decoration-none" tag={Link} active>{page.name}</BreadcrumbItem>
        ))}
      </Breadcrumb>
    </>
  );
};

CustomBreadCrumbs.propTypes = {
  title: PropTypes.string,
  root: PropTypes.string,
  pages: PropTypes.array,
};

export default CustomBreadCrumbs;
