
export const SectionOfOperation = {
    WA: 'WA',
    CENTRAL: 'CENTRAL',
    QLD: 'QLD',
    SE: 'SE',
    DEMO: 'DEMO'
}

export class SectionOfOperationCubit {

    /* Site_SectionOfOperation & Site_SectionEnv variables are coming from site_config.js file in the root
     * once this file is uploaded then we can remove that from the the build as the file is there on the server with related configs.
     * if the config is not there then it will try to use old code to identify the server based on the URL of the server.
     * */

    static get fromUrlString() {
        let SectionOfOperationRJ = "";
        try {
            SectionOfOperationRJ = Site_SectionOfOperation
        } catch (e) {

        }

        if (SectionOfOperationRJ !== undefined && SectionOfOperationRJ !== null && SectionOfOperationRJ !== "") {
            if (SectionOfOperationRJ.toString().toLowerCase() === "central")
                return SectionOfOperation.CENTRAL;
            else if (SectionOfOperationRJ.toString().toLowerCase() === "qld")
                return SectionOfOperation.QLD;
            else if (SectionOfOperationRJ.toString().toLowerCase() === "se")
                return SectionOfOperation.SE;
            else if (SectionOfOperationRJ.toString().toLowerCase() === "wa")
                return SectionOfOperation.WA;
        }

        const url = window.location.href.toString().toLowerCase();
        if (url.indexOf('.wa') > 0 || url.indexOf('wa.') > 0) {
            return SectionOfOperation.WA;
        } else if (url.indexOf('.central') > 0 || url.indexOf('central.') > 0 || url.indexOf('ehr.flyingdoctor.net') >= 0) {
            return SectionOfOperation.CENTRAL;
        } else if (url.indexOf('.qld') > 0 || url.indexOf('qld.') > 0) {
            return SectionOfOperation.QLD;
        } else if (url.indexOf('.se') > 0 || url.indexOf('se.') > 0) {
            return SectionOfOperation.SE;
        } else if (url.indexOf('central') > 0) {
            return SectionOfOperation.CENTRAL;
        } else if (url.indexOf('qld') > 0) {
            return SectionOfOperation.QLD;
        } else if (url.indexOf('wa') > 0) {
            return SectionOfOperation.WA;
        } else if (url.indexOf('se') > 0) {
            return SectionOfOperation.SE;
        }
        return SectionOfOperation.DEMO;
    };

    static get SectionServer() {
        let SectionEnvRJ = "";
        try {
            SectionEnvRJ = Site_SectionEnv
        } catch (e) {

        }

        if (SectionEnvRJ !== undefined && SectionEnvRJ !== null && SectionEnvRJ !== "") {
            if (SectionEnvRJ.toString().toLowerCase() === "training")
                return "training";
            else if (SectionEnvRJ.toString().toLowerCase() === "sandbox")
                return "sandbox";
            return "live"
        }
    };
}

export class Sections {

    static get isCentral() {
        return SectionOfOperationCubit.fromUrlString === SectionOfOperation.CENTRAL;
    };

    static get isSE() {
        return SectionOfOperationCubit.fromUrlString === SectionOfOperation.SE;
    };

    static get isQLD() {
        return SectionOfOperationCubit.fromUrlString === SectionOfOperation.QLD;
    };

    static get isWA() {
        return SectionOfOperationCubit.fromUrlString === SectionOfOperation.WA;
    };

    static get isDEMO() {
        return SectionOfOperationCubit.fromUrlString === SectionOfOperation.DEMO;
    };
}