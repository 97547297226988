import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarFormSectionList: [],
  activeSideBarName: ''
};

export const sidebarPatientFormsSectionSlice = createSlice({
  name: 'sidebarPatientFormsSection',
  initialState,
  reducers: {
    SideBarFormSectionListAction: (state, action) => {
      state.sidebarFormSectionList = action.payload;
    },
    ActiveSideBarAction: (state, action) => {
      state.activeSideBarName = action.payload;
    },
  },
});

export const { SideBarFormSectionListAction, ActiveSideBarAction } =
  sidebarPatientFormsSectionSlice.actions;

export default sidebarPatientFormsSectionSlice.reducer;
