import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mergedIds: [],
    selectedMergedData: [],
    PinnedEpisodes: [],
    primaryMerged: null,
    AtRiskParams: [],
    AtRisk: 'N',
    PFAObsInterventionTime: '',
    Weight: '',
    Helgit: '',
    TaskingStatus: '',
    NilKnownAllergy: false,
    UnKnownAllergy: false,
    ResponsePriority: '',
    JourneyBookTime: '',
    AllUsers: [],
    EpisodeStatus: '',
    AllergyEmeContPermission: [],
    SaveCallActive: false,
    CurrentFormID: '',
    ReloadCurrentForm: '',
    T13editEpisode: true,
};

export const patientTaskSlice = createSlice({
    name: 'patientTask',
    initialState,
    reducers: {
        mergedIdsStateChange: (state, action) => {
            const findIndex = state.mergedIds.findIndex(obj => obj.id == action.payload.id)
            if (findIndex > -1) {
                const filteredIndexIds = state.mergedIds.filter(obj => obj.id !== action.payload.id)
                state.mergedIds = filteredIndexIds
            } else {
                state.mergedIds = [...state.mergedIds, action.payload]
            }
        },
        selectedMergedIdsStateChange: (state, action) => {
            const findIndex = state.selectedMergedData.findIndex(obj => obj.id == action.payload.id)
            if (findIndex > -1) {
                const filteredIndexIds = state.selectedMergedData.filter(obj => obj.id !== action.payload.id)
                state.selectedMergedData = filteredIndexIds
            } else {
                state.selectedMergedData = [...state.selectedMergedData, action.payload]
            }
        },
        primaryMergedStateChange: (state, action) => {
            state.primaryMerged = action.payload
        },
        PinnedEpisodesChange: (state, action) => {
            state.PinnedEpisodes = action.payload
        },
        AtRiskParamsAction: (state, action) => {
            state.AtRiskParams = action.payload;
        },
        AtRiskAction: (state, action) => {
            state.AtRisk = action.payload;
        },
        WeightAction: (state, action) => {
            state.Weight = action.payload;
        },
        HeightAction: (state, action) => {
            state.Height = action.payload;
        },
        TaskingStatusAction: (state, action) => {
            state.TaskingStatus = action.payload;
        },
        NilKnownAllergyAction: (state, action) => {
            state.NilKnownAllergy = action.payload;
        },
        UnKnownAllergyAction: (state, action) => {
            state.UnKnownAllergy = action.payload;
        },
        AllUsersAction: (state, action) => {
            state.AllUsers = action.payload;
        },
        ResponsePriorityAction: (state, action) => {
            state.ResponsePriority = action.payload;
        },
        JourneyBookTimeAction: (state, action) => {
            state.JourneyBookTime = action.payload;
        },
        EpisodeStatusAction: (state, action) => {
            state.EpisodeStatus = action.payload;
        },
        AllergyEmeContPermissionAction: (state, action) => {
            state.AllergyEmeContPermission = action.payload;
        },
        SaveCallActiveAction: (state, action) => {
            state.SaveCallActive = action.payload;
        },
        CurrentFormIDAction: (state, action) => {
            state.CurrentFormID = action.payload;
        },
        ReloadCurrentFormAction: (state, action) => {
            state.ReloadCurrentForm = action.payload;
        },
        T13editEpisodeAction: (state, action) => {
            state.T13editEpisode = action.payload;
        },
    },
});

export const { mergedIdsStateChange, selectedMergedIdsStateChange, primaryMergedStateChange, AtRiskParamsAction, AtRiskAction,
    WeightAction, HeightAction, NilKnownAllergyAction, AllUsersAction, PinnedEpisodesChange, TaskingStatusAction, UnKnownAllergyAction,
    ResponsePriorityAction, JourneyBookTimeAction, EpisodeStatusAction, AllergyEmeContPermissionAction, SaveCallActiveAction, CurrentFormIDAction,
    ReloadCurrentFormAction, T13editEpisodeAction } = patientTaskSlice.actions;

export default patientTaskSlice.reducer;
