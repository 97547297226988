import CryptoJS from "crypto-js";

export const isAuthicated = () => {
    const auth = JSON.parse(localStorage.getItem('auth'));
    let result = false;

    if (auth !== null) {
        result = true;
    }

    return result;
}

export const isRoleCorrect = (roles) => {
    const auth = JSON.parse(localStorage.getItem('auth'));

    if (["Clinician", "Clinician Read Only", "System Admin", "Data Admin"].includes(auth.permission_role)) {
        localStorage.clear();
        window.location.href = "/auth/login";
    }

    let result = false;

    if (auth !== null) {
        roles.forEach((role) => {
            let decRole = decrypt(auth.permission_role);
            if (role == decRole) {
                result = true;
            }
        })
    }

    return result;
}

export const checkAccessTokenValid = (response) => {
    let result = true;
    if (response.status == 401) {
        result = false;
    }
    return result;
}

export const limiteCreateAssignAccess = (type) => {
    const auth = JSON.parse(localStorage.getItem('auth'));

    if (auth?.permission_role == "Clinician Read Only" && type == "create") {
        document.querySelector('.create-new-task').disabled = true;
    }
}

export const limitReadWriteAccess = () => {
    const auth = JSON.parse(localStorage.getItem('auth'));

    if (auth?.permission_role == "Clinician Read Only") {
        let allInputs = document.querySelectorAll('#patientform input');
        let allButtons = document.querySelectorAll('#patientform button');
        let medicationTableButton = document.querySelectorAll('#patientform .medication-table-icon');
        let select = document.querySelectorAll('#patientform select:not(.can-not-save)');
        let textarea = document.querySelectorAll('#patientform textarea');

        allInputs.forEach((input) => {
            input.disabled = true;
        });

        allButtons.forEach((button) => {
            button.disabled = true;
        })

        medicationTableButton.forEach((button) => {
            button.style.display = "none";
        })

        select.forEach((s) => {
            s.disabled = true;
        })

        textarea.forEach((t) => {
            t.disabled = true;
        })
    }
}

export const encrypt = (data) => {
    const secretPass = "ckhQG4nW2Y9R";
    const encData = CryptoJS.AES.encrypt(data, secretPass, { mode: CryptoJS.mode.ECB }).toString();
    return encData;
}

export const decrypt = (data) => {
    const secretPass = "ckhQG4nW2Y9R";
    const bytes = CryptoJS.AES.decrypt(data, secretPass, { mode: CryptoJS.mode.ECB });
    const decData = bytes.toString(CryptoJS.enc.Utf8);
    return decData;
}