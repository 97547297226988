import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  obsFields: [],
  obsAgeChartType: '',
  isObsObstetric: false,
  initialIsObstetric: false,
};

export const obsChartSlice = createSlice({
  name: 'obsChart',
  initialState,
  reducers: {
    ObsSetFieldsAction: (state, action) => {
      state.obsFields = action.payload;
    },
    ObsAgeChartTypeAction: (state, action) => {
      state.obsAgeChartType = action.payload;
    },
    ObsIsObstetricAction: (state, action) => {
      state.isObsObstetric = action.payload;
    },
    ObsInitialIsObstetricAction: (state, action) => {
      state.initialIsObstetric = action.payload;
    },
  },
});

export const { ObsSetFieldsAction, ObsAgeChartTypeAction, ObsIsObstetricAction, ObsInitialIsObstetricAction } =
  obsChartSlice.actions;

export default obsChartSlice.reducer;
